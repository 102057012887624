import { Vue, Options } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { StormService } from "@/axios/stormservice";
import { FilterModel, ProductModel } from "@/models/stormmodels";
import { OrderLine } from "@/models/ordermodels";
import VariantSelector from "@/views/fabricselector/VariantSelector.vue";
import { UIStore } from "@/store/ui";

@Options({
    components: {
        VariantSelector
    },
    emits: [
        "select-variant"
    ],
})
export default class ProductSelector extends Vue {
    protected filters: FilterModel[] = [];
    protected products: ProductModel[] = [];
    protected variantId: number | null = null;
    protected searchText: string = "";
    protected selectedProduct: ProductModel | null = null;
    private activeFilters: string[] = [];
    private filterGroupWidthId: string = "";
    private filterGroupSoundClassId: string = "";

    @Prop()
    protected orderLine!: OrderLine;
    
    @Prop()
    protected isNextButtonEnabled!: boolean;

    @Watch("searchText")
    protected watchSearchText(): void {
        this.searchProducts();
    }

    async created(): Promise<void> {
        const { filters, products } = await StormService.getFilters();
        this.filters = filters;

        if (filters !== undefined) {
            this.filterGroupWidthId = filters[0].id;
            this.filterGroupSoundClassId = filters[1].id;
        }        

        this.products = products;

        if (products.length > 0) {
            if (this.orderLine && this.orderLine.erpProductId && this.orderLine.ecomProductId > 0) {
                this.selectProduct(this.orderLine.ecomProductId);
            } else {
                this.selectProduct(products[0]);
            }
        }
    }

    protected isActiveFilterOption(code: string): boolean {
        return this.activeFilters.indexOf(code) !== -1;
    }

    protected selectProduct(product: ProductModel): void;
    protected selectProduct(product: number): void;
    protected selectProduct(product: ProductModel | number): void {
        if (typeof product === "number") {
            const selectedProduct = this.products.find(x => x.productId === product);
            if (selectedProduct) {
                this.selectedProduct = selectedProduct;
            }
        } else {
            this.selectedProduct = product;
        }
    }

    protected variantSelectorSelectVariant(variant: ProductModel, userInitiated: boolean): void {
        this.$emit("select-variant", this.selectedProduct, variant, userInitiated);
    }

    protected onFilterChange(e: Event): void {
        if (e && e.target) {
            const target = (e.target as HTMLInputElement);

            let filterGroupBeingFiltered = this.filterGroupWidthId;
            if (!target.value.includes(this.filterGroupWidthId)){
                filterGroupBeingFiltered = this.filterGroupSoundClassId;
            }

            const filterIndex = this.activeFilters.indexOf(target.value);
            if (filterIndex === -1) {
                this.activeFilters = this.activeFilters.filter(item => !(item.includes(filterGroupBeingFiltered)));
                this.activeFilters.push(target.value);
            } else if (filterIndex !== -1) {
                this.activeFilters.splice(filterIndex, 1);
            }
            this.searchProducts();
        }
    }

    protected async searchProducts(): Promise<void> {

        let filter: string = "";

        if (this.activeFilters.length > 0) {
            filter = "parf|" + this.activeFilters.join("*");
        }

        const products = await StormService.getProducts(this.searchText, filter);

        if (products.length > 0) {
            this.products = products;
            this.selectProduct(products[0]);
        }
    }
}
