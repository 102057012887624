import { defineComponent, ref } from "vue";
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router"
import { OrderStore } from "@/store/orderstate";
import { Order, Folder } from "@/models/ordermodels";
import { OrderService } from "@/services/OrderService";
import { RouteParams } from "@/router/RouteParams";

export default defineComponent({

    name: "Folder",

    computed: {
        isComplete(): boolean {
            return this.markingName !== '' && !this.markingSaving;
        }
    },

    setup() {
        if (!OrderStore.order) {
            throw "Order not found";
        }

        const route = useRoute();
        const router = useRouter();
        const getFolder = (id: string): Folder => {
            const folder = order.value.folders.get(id);
            if (!folder) {
                throw `Folder ${id} not found`;
            }
            return folder;
        };
        const order = ref<Order>(OrderStore.order);
        const folder = ref<Folder>(getFolder(new RouteParams(route.params).folder()));
        const markingName = ref("");
        const markingSaving = ref<boolean>(false);

        const handleOnCloseClick = ():void =>  {
          router.push({ name: "folderview", params: { order: order.value.id, folder: folder.value.id } });
        };

        const submit = async () => {
          markingSaving.value = true;
          const marking = await OrderService.createMarking({
            name: markingName.value,
            folderId: folder.value.id,
          });
          await OrderService.loadOrder(order.value.id);
          router.push({ name: "marking", params: { order: order.value.id, marking: marking.id, step: 2 } });
        };

        onBeforeRouteUpdate(async (to) => {
            folder.value = getFolder(new RouteParams(to.params).folder());
        });

        return {
          order,
            folder,
            markingName,
            submit,
            handleOnCloseClick,
            markingSaving
        }
    }
});
